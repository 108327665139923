<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: medicineTable.vue
Description: This file is the part of the medicine page.It has table component which is useful for taking the parameters of the medicine usage.
-->
<template>
  <el-row
    class="culture-medicine-table"
    v-loading="pageloading"
    element-loading-background="white"
  >
    <div class="loading_class" v-if="pageloading"></div>
    <er-data-tables
      v-show="!pageloading"
      type="white-header-table"
      :table-data="tableDataArr"
      uniqueId="medicine-table"
      :filters="filters"
      :columns="tableColumns"
      :el-table-props="tableProps"
      :paginationSlot="true"
      :actionColumn="false"
      :key="$i18n.locale"
    >
      <template v-slot:pond_id="{ row }">
        <div class="pond-container">
          <er-select
            v-model="row.data.pond_id"
            size="mini"
            :placeholder="$t('Ponds_select_pond_name')"
            :key="getDate"
          >
            <el-option
              v-for="pond in getPondsInCulture"
              :key="pond._id"
              :value="pond._id"
              :label="pond.title"
            ></el-option>
          </er-select>
        </div>
      </template>
      <template v-slot:type="{ row }">
        <er-select
          v-model="row.data.type"
          size="mini"
          :placeholder="$t('Medicines_variety')"
          @change="handleChangeResourceType($event, 'type', row.data)"
        >
          <el-option
            v-for="(resourceLabel, resourceKey) in resourceTypes"
            :key="resourceKey"
            :value="resourceKey"
            :label="$t(resourceLabel)"
          ></el-option>
        </er-select>
      </template>

      <template v-slot:resource_id="{ row }">
        <div v-if="row.data.type != 'OTHER'">
          <er-select
            v-model="row.data.resource_id"
            size="mini"
            :placeholder="$t('Comn_resource')"
            @change="handleChangeResourceType($event, 'resource', row.data)"
          >
            <el-option
              v-for="resource in getObjOfResourcesByType[row.data.type]"
              :key="resource._id"
              :value="resource._id"
              :label="resource.name"
            ></el-option>
          </er-select>
        </div>
        <div v-else>
          <el-input
            class="others-input"
            :placeholder="$t('Comn_others')"
            v-model="row.data.resource_name"
            size="mini"
          ></el-input>
        </div>
      </template>
      <template v-slot:notes="{ row }">
        <el-input
          type="textarea"
          autosize
          :rows="2"
          :placeholder="$t('Comn_note_here')"
          v-model="row.data.notes"
        ></el-input>
      </template>
      <template v-slot:quantity="{ row }">
        <div class="quantity-input">
          <el-input-number
            v-model="row.data.quantity"
            :placeholder="$t('Comn_quantity')"
            :controls="false"
            size="mini"
            :min="0"
            :max="100000"
          ></el-input-number>
          <er-select
            v-model="row.data.units"
            size="mini"
            :disabled="row.data.type != 'OTHER'"
            :placeholder="$t('Comn_units')"
          >
            <el-option
              v-for="unit in units"
              :key="unit.key"
              :value="unit.key"
              :label="unit.name"
            ></el-option>
          </er-select>
        </div>
      </template>

      <template v-slot:actions="{ row }">
        <template v-if="row.$index === tableDataLength - 1">
          <er-button
            btnType="add"
            type="text"
            size="mini"
            :showLabel="true"
            :showIcon="true"
            :disabled="isReadOnly"
            @click="handleAddRow()"
          ></er-button>
        </template>
        <template v-else>
          <er-button
            btnType="delete"
            type="text"
            :showLabel="true"
            :showIcon="true"
            size="mini"
            :disabled="isReadOnly"
            @click="handleDeleteRow(row.$index)"
          ></er-button>
        </template>
      </template>
      <template slot="pagination">
        <er-button
          btnType="save"
          :showLabel="true"
          :showIcon="true"
          :loading="loading"
          size="mini"
          :disabled="isReadOnly"
          @click="handleSaveTableData"
        ></er-button>
        <er-button
          btnType="cancel"
          :showLabel="true"
          size="mini"
          @click="handleCancel"
          :disabled="isReadOnly"
        ></er-button>
      </template>
    </er-data-tables>
  </el-row>
</template>

<script>
import Medicine from "@/model/medicine";
import { mapGetters, mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import { permissionsToStringMap as permissions } from "@/middleware/pageAccessManager";

export default {
  name: "MedicinesTable",
  mixins: [errorHandlerMixin, datesHandlerMixin],

  props: {
    pageloading: {
      default: false
    }
  },
  data: function () {
    return {
      loading: false,
      tableDataArr: [new Medicine()],
      tableProps: {
        // border: true,
        size: "small"
      },
      filters: [
        {
          value: "",
          filterFn: (row, filter) => {
            return Object.keys(row).some((prop) => {
              if (["resource_name"].indexOf(prop) > -1) {
                return row[prop]
                  .toLowerCase()
                  .includes(filter.value.toLowerCase());
              }
            });
          }
        }
      ],
      units: [
        { name: "Kgs", key: "KG" },
        { name: "L", key: "L" },
        { name: "Units", key: "UNITS" }
      ],
      permissionsData: {
        name: "medicines",
        keyToValidateAccess: "PERMISSIONS",
        PERMISSIONS: [permissions.MANAGE_MEDICINE]
      }
    };
  },
  watch: {
    getDate(newDate, oldDate) {
      this.tableDataArr = [new Medicine()];
    }
  },
  computed: {
    ...mapGetters("pond", {
      getPonds: "getPonds"
    }),
    ...mapGetters("resource", {
      getArrResources: "getArrResources",
      getObjResources: "getObjResources",
      isReadOnly: "isReadOnly"
    }),
    ...mapGetters("user", {
      isReadOnly: "isReadOnly"
    }),
    ...mapGetters("medicines", {
      getDate: "getDate"
    }),
    getDateObjForSelectedDate: function () {
      return this.dhm__dateUtilsLib.toDate(
        this.dhm__dateUtilsLib.utcToZonedTime(
          this.dhm__castUserUTCToUTCISO(new Date(this.getDate || 1)),
          this.getUserTimeZoneString
        )
      );
    },
    getPondsInCulture() {
      return this.getPonds.filter(({ cultivation_date }) => {
        const stockingDate = this.dhm__castUserUTCToUTCDateObj(
          cultivation_date || this.dhm__dateUtilsLib.minTimeISO
        );
        return this.dhm__dateUtilsLib.isBefore(
          stockingDate,
          this.getDateObjForSelectedDate
        );
      });
    },
    getObjOfResourcesByType() {
      const newTemps = this.$lodash.cloneDeep(this.getArrResources || []);
      const resourceTypeToResourceMap = newTemps.reduce((acc, curr) => {
        if (acc[curr.type] === undefined) {
          acc[curr.type] = [];
        }
        acc[curr.type].push(curr);
        return acc;
      }, {});
      for (const key in resourceTypeToResourceMap) {
        resourceTypeToResourceMap[key].sort((a, b) =>
          this.$commonUtils.alphaNumericComparator(a.name, b.name)
        );
      }
      return resourceTypeToResourceMap;
    },
    tableDataLength: function () {
      return this.tableDataArr.length;
    },
    resourceTypes() {
      return {
        MEDICINE: this.$t("Comn_stock_medicine"),
        MINERAL: this.$t("Comn_mineral"),
        PRO_BIOTIC: this.$t("Medicine_pro_biotic"),
        DO_SUBSTITUTE: this.$t("Comn_stock_do_substitute"),
        WATER_CONDITIONER: this.$t("Comn_stock_water_conditioner"),
        CARBON_SOURCE: this.$t("Comn_stock_carbon_source"),
        DISINFECTANT: this.$t("Comn_stock_disinfectant"),
        FERTILIZER: this.$t("Comn_stock_fertilizer"),
        FEED_ADDITIVES: this.$t("Comn_stock_feed_additives"),
        ORGANIC_ACID: this.$t("Comn_stock_organic_acid"),
        OTHER: this.$t("Comn_others")
      };
    },
    tableColumns() {
      return {
        pond_id: {
          prop: "pond_id",
          label: this.$t("Comn_pond_name"),
          minWidth: 90,
          align: "left"
        },
        type: {
          prop: "type",
          label: this.$t("Medicines_variety"),
          minWidth: 90,
          align: "left"
        },
        resource_id: {
          prop: "resource_id",
          label: this.$t("Comn_resource"),
          minWidth: 90,
          align: "left"
        },
        quantity: {
          prop: "quantity",
          label: this.$t("Comn_quantity"),
          minWidth: 135,
          align: "left"
        },
        notes: {
          prop: "notes",
          label: this.$t("Comn_note"),
          minWidth: 120,
          align: "left"
        },
        actions: {
          prop: "actions",
          label: this.$t("Comn_actions"),
          minWidth: 60,
          align: "left"
        }
      };
    }
  },
  methods: {
    ...mapActions("medicines", {
      addMedicinesToPonds: "addMedicinesToPonds"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    async handleChangeResourceType(resourceType, type, data) {
      if (type === "type") {
        data.resource_id = '';
      }
      data.units = this.getObjResources[data.resource_id].units;
    },
    handleAddRow() {
      try {
        this.mixPanelEventGenerator({ eventName: "Medicines Table - Add Row" });
        this.$gblUAMCanUserEdit(this.permissionsData);
        this.tableDataArr.push(new Medicine());
      } catch (error) {
        this.ehm__errorMessages(error, true);
      }
    },
    handleDeleteRow(rowIndexPos) {
      this.tableDataArr.splice(rowIndexPos, 1);
    },
    async handleSaveTableData() {
      this.loading = true;
      const errors = [];
      try {
        this.$gblUAMCanUserEdit(this.permissionsData);
        const copyArray = [...this.tableDataArr];
        const newRecords = copyArray.map(
          (
            {
              pond_id,
              type,
              resource_id,
              quantity,
              date,
              notes,
              resource_name,
              units
            },
            index
          ) => {
            if (!pond_id) {
              errors.push({
                message: `${this.$t("Please_select_a_pond_in_row")} row ${
                  index + 1
                }`
              });
            } else if (!type) {
              errors.push({
                message: `${this.$t("Please_select_a_type_of_input_in_row")} row ${
                  index + 1
                }`
              });
            } else if (!resource_id && !resource_name) {
              errors.push({
                message: `${this.$t("Please_select_a_resource_in_row")} row ${
                  index + 1
                }`
              });
            } else if (!quantity) {
              errors.push({
                message: `${this.$t(
                  "Quantity_field_should_not_empty_row"
                )} in row ${index + 1}`
              });
            } else if (resource_name && !units) {
              errors.push({
                message: `Units missing in row ${index + 1}`
              });
            }

            if (errors.length > 0) {
              throw {
                type: "FAIL_TO_SAVE",
                errors: errors
              };
            }

            if (resource_id) {
              return {
                pond_id,
                type,
                resource: resource_id,
                quantity: quantity.toFixed(2),
                date: `${this.getDate}T00:00:00.000Z`,
                notes
              };
            } else {
              return {
                pond_id,
                type,
                resource_name,
                units,
                quantity: quantity.toFixed(2),
                date: `${this.getDate}T00:00:00.000Z`,
                notes
              };
            }
          }
        );
        await this.addMedicinesToPonds(newRecords);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Medicines_created_success"),
          duration: 5000,
          type: "success"
        });
        this.tableDataArr = [new Medicine()];
        this.mixPanelEventGenerator({ eventName: "Medicines Table - Save" });
      } catch (error) {
        // const err = error.

        let formatedError = {};
        if (
          error.response &&
          error.response.data.errors.details[0].error_code ===
            "RESOURCE_ALREADY_EXISTS"
        ) {
          formatedError = {
            type: "FAIL_TO_SAVE",
            errors: error.response.data.errors.details.map(errorDetail => ({
                message: `${this.$t("resource_already_exists")} in row ${errorDetail.key + 1}`
              }))
          };
        } else if (
          error.response &&
          error.response.data.errors.details[0].error_code === "INACTIVE_POND"
        ) {
          formatedError = {
            type: "FAIL_TO_SAVE",
            errors: [{ message: this.$t("this-pond-is-inactive-ponds") }]
          };
        } else {
          formatedError = error;
        }
        this.ehm__errorMessages(formatedError, true);
      } finally {
        this.loading = false;
      }
    },
    async handleCancel() {
      this.tableDataArr = [new Medicine()];
      await this.$emit("reload");
    }
  }
};
</script>

<style lang="scss">
.culture-medicine-table {
  .loading_class {
    @include responsiveProperty(min-height, 78vh, 81vh, 85vh);
    width: 100%;
    padding: 0px;
    margin: 0px;
    background-color: white;
    text-align: center;
  }
  .pond-container {
    .el-select {
      width: 125px;
    }
  }
  .el-textarea__inner,
  .el-textarea {
    @include responsiveProperty(font-size, 11px, 13px, 16px);
    width: 180px;
  }
  .others-container {
    display: flex;
    .el-select {
      width: 95px !important;
      .el-input {
        width: 95px !important;
      }
    }
    .quantity-input {
      margin-right: 16px;
    }
  }
  .others-input {
    .el-input__inner {
      @include responsiveProperty(width, 120px, 140px, 180px);
    }
  }
  .quantity-input {
    display: flex;
    align-items: center;
    .el-input-number {
      width: 80px;
      margin-right: 16px;
    }
    .er-select {
      @include responsiveProperty(width, 100px, 100px, 100px);
    }
  }
}
</style>
